import React, { useState } from "react";
import styles from "./bloges.module.css";
import NavBar from "../Components/NavBar";
import classNames from "classnames";
import Footer from "../Components/Footer";
import AdBar from "../Components/AdsBar";
const Bloges = () => {
  return (
    <div>
      <AdBar />
      <NavBar />

      <Footer />
    </div>
  );
};

export default Bloges;
