import logo from "./logo.svg";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  BrowserRouter,
} from "react-router-dom";
import HomePage from "./Home";
import AboutUs from "./AboutUs";
import ContactUs from "./ContactUs";
import Bloges from "./Bloges";
import TermsCondition from "./TermsConditions";
import PrivacyPolicy from "./PrivacyPolicy";
import Disclaimer from "./Discliamber";
import Internet from "./Internet";
import Spectrum from "./Specturm";
import Verizon from "./Verizon";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/aboutUs" element={<AboutUs />} />
          <Route path="/internet" element={<Internet />} />
          <Route path="/homephone" element={<ContactUs />} />
          <Route path="/termsCondition" element={<TermsCondition />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/spectrum" element={<Spectrum />} />
          <Route path="/verizon" element={<Verizon />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
