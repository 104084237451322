import React, { useState } from "react";
import styles from "./faq.module.css";
import classNames from "classnames";
import commonstyle from "./faq.module.css";
import { Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { FaPlus } from "react-icons/fa";

export default function FAQS22() {
  const accordionData = [
    {
      header:
        "Where is Spectrum Home Internet available? And what home internet services are available to me?",
      Content:
        "The availability of internet services depends on the technology used, how it's delivered to your home, and your location. Spectrum Home Internet is widely accessible in both urban and suburban areas across the USA. 5G Home Internet is increasingly available in many cities nationwide. LTE Home Internet uses the wireless 4G LTE network to deliver broadband internet service, and it's available in many areas where Spectrum and 5G Home Internet are not yet accessible. To find out which Spectrum Home Internet service is available at your location, simply enter your address above and click 'Check Availability'.",
    },
    {
      header: "What is 5G Home Internet?",
      Content:
        "5G Home Internet is a high-speed, easy-to-use wireless home internet service powered by 5G Ultra Wideband technology. It offers exceptional network performance and fast speeds to support your entire home, even with many devices connected at once. There are no annual contracts, hidden fees, data caps, or equipment charges. Enjoy competitive pricing with Auto Pay and discounts when bundled with select 5G mobile plans. The service price is locked in for up to 3 years, and you can receive up to a $500 credit to cover any early termination fees when switching to Spectrum. Setup is simple with a plug-and-play installation, and 30 days of support is included. 5G Ultra Wideband is expanding across the country, bringing more people access to ultra-fast broadband. To see if your address qualifies, enter it above and click 'Check Availability'.",
    },
    {
      header: "What is Spectrum Internet?",
      Content:
        "Spectrum Internet is powered by a 100% fiber-optic network, providing some of the fastest internet speeds to millions of homes across the United States. Fiber optics transmit data at the speed of light, delivering faster internet connections and powerful bandwidth to support multiple devices. Spectrum is one of the few ISPs that offer symmetric download and upload speeds on most of their plans, making it ideal for video conferencing, gaming, and sharing large files.",
    },
    {
      header: "What is LTE Home Internet?",
      Content:
        "LTE Home Internet is a home broadband service powered by Spectrum's 4G LTE network. It is available in areas where Spectrum's other broadband services (such as Spectrum, 5G Home, etc.) are not yet offered. With LTE Home Internet, you can stream videos in 1080p and enjoy unlimited data with no data usage limits.",
    },
    {
      header:
        "Can Spectrum help cover my early termination fee when I switch to Spectrum Home Internet?",
      Content:
        "Yes! If you are currently under a contract with another home internet provider, you can switch to Spectrum Home Internet and receive up to a $500 credit to help cover your early termination fees. To qualify for the credit, you must submit your request within 90 days of your Spectrum Home Internet activation. Refer to the Spectrum Early Termination Fee instructions for Fios and 5G Home services to learn how to claim your credit.",
    },
    {
      header:
        "Can I transfer my current Spectrum Home Internet service to a new address?",
      Content:
        "Yes, Spectrum makes it easy to transfer your home internet service when you move. Visit our page on transferring Spectrum Home Internet service or log into your account to begin the process.",
    },
    {
      header: "What is Spectrum Whole-Home Wi-Fi?",
      Content:
        "Spectrum Whole-Home Wi-Fi includes two key features that enhance Wi-Fi coverage in your home: Wi-Fi Health Check in the Spectrum app to optimize your network, and an optional Wi-Fi Extender for stronger signal coverage in hard-to-reach areas. If you cancel your service or switch to a plan that doesn't include Whole-Home Wi-Fi, you must return the Spectrum-owned Wi-Fi Extender, or you will be charged an unreturned equipment fee of $175.",
    },
    {
      header: "What affordable Spectrum Home Internet options are available?",
      Content:
        "Spectrum participated in the Affordable Connectivity Program (ACP) while it was active through April 2024. Now, Spectrum offers reduced-cost internet to new or existing home internet customers who qualify for assistance programs such as Lifeline, SNAP, WIC, and others within 180 days of application or who have received a Federal Pell Grant within the past year through the Spectrum Forward program. Qualifying customers can enjoy discounts on Spectrum, 5G Home, or LTE Home Internet plans. Learn more here.",
    },
  ];

  const [expanded, setExpanded] = useState(null);

  const handleAccordionChange = (panelIndex) => (event, isExpanded) => {
    setExpanded(isExpanded ? panelIndex : null);
  };

  return (
    <div className={styles.container}>
      {accordionData.map((data, index) => (
        <div key={index} className={styles.mt8}>
          <Accordion
            className={styles.dropdown}
            expanded={expanded === index}
            onChange={handleAccordionChange(index)}
          >
            <AccordionSummary
              sx={{
                ".MuiAccordionSummary-expandIconWrapper": {
                  transform: "none",
                  transition: "none",
                },
              }}
              style={{ padding: "10px 0px" }}
              expandIcon={
                <FaPlus
                  className={classNames(styles.icon, {
                    [styles.rotate]: expanded === index,
                    [styles.colorChanged]: expanded === index,
                  })}
                />
              }
              aria-controls={`panel${index + 1}-content`}
              id={`panel${index + 1}-header`}
            >
              <Typography style={{ fontWeight: "700" }}>
                <div>
                  <p
                    className={classNames(commonstyle.fs16)}
                    style={{
                      color: expanded === index ? "blue" : "blue",
                      textAlign: "start",
                    }}
                  >
                    {data.header}
                  </p>
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{
                padding: "8px 0px 16px",
                textAlign: "start",
                textJustify: "none",
              }}
            >
              <Typography>
                <div>
                  <p style={{ fontSize: "14px", color: "#666" }}>
                    {data.Content}
                  </p>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      ))}
    </div>
  );
}
