import React from "react";
import Carousel from "react-multi-carousel";
import classNames from "classnames";
import "react-multi-carousel/lib/styles.css";
import style from "./homeslider.module.css";
import SWAT from "../../assests/Zulfi.jpeg";
import HUNZA from "../../assests/Tahmina.jpeg";
import SKARDU from "../../assests/touseef.jpeg";
import UMBRELLA from "../../assests/Sarfraz.jpeg";
import NATHIA from "../../assests/Capture.PNG";
import SALT from "../../assests/bilalAzam.png";
import FAIRY from "../../assests/Userr2.jpeg";
import GILGIT from "../../assests/bilalAzam.png";
import PishinValley from "../../assests/Userr2.jpeg";

import { Laptop } from "@mui/icons-material";

const responsive = {
  lgdesktop: {
    breakpoint: { max: 3000, min: 1441 },
    items: 2,
    slidesToSlide: 1,
  },
  desktop: {
    breakpoint: { max: 1440, min: 1041 },
    items: 2,
    slidesToSlide: 1,
  },
  Laptop: {
    breakpoint: { max: 1040, min: 769 },
    items: 2,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 768, min: 481 },
    items: 1,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 480, min: 320 },
    items: 1,
    slidesToSlide: 1,
  },
};

const sliderItems = [
  {
    imageUrl: "https://internetreseller.us/assets/img/project/prooject-1.png",
    Heading: "Top Cable Service Providers",
    Heading2: "Case Study, Expansion",
    text: "As a premier cable provider, we offer access to a wide range of TV channels and entertainment options. Experience top-quality picture clarity and a variety of programming options that cater to your preferences. Select from different packages with diverse channels and features to maximize your value. Whether you're into sports, movies, or family entertainment, we provide reliable cable services that fit your needs.",
  },
  {
    imageUrl: "https://internetreseller.us/assets/img/project/prooject-2.png",
    Heading: "Reliable Wireless Internet",
    Heading2: "Case Study, Growth",
    text: "For wireless internet, we provide fast and dependable connectivity without the hassle of cables. Our wireless services ensure smooth browsing and uninterrupted streaming. We offer a variety of plans to match different requirements and budgets. Our dedicated customer support team is always ready to assist you. Choose us today and enjoy the freedom of wireless internet.",
  },
  {
    imageUrl: "https://internetreseller.us/assets/img/project/prooject-3.png",
    Heading: "High-Speed Internet Providers",
    Heading2: "Case Study, Success",
    text: "If you're in need of fast internet, we’re your top choice. As a leading provider, we guarantee stable, fast connections for seamless browsing and streaming. With multiple plans to choose from, you can find one that best fits your needs and budget. Enjoy perks like free installation and our always-ready support team. Choose us now for high-speed internet that delivers on every requirement.",
  },
];

const HomeSlider = () => {
  return (
    <div className="parent" style={{ marginBottom: "24px" }}>
      <Carousel
        responsive={responsive}
        autoPlay={true}
        swipeable={true}
        draggable={true}
        showDots={false}
        infinite={true}
        dotListClass="custom-dot-list-style"
      >
        {sliderItems.map((item, index) => (
          <div className={style.slider} key={index}>
            <img src={item.imageUrl} className={style.imgs} />
            <div className={style.overlay}>
              <h1 className={classNames(style.heading)}>{item.Heading}</h1>
              <h3 style={{ textAlign: "center" }}>{item.Heading2}</h3>

              <p className={classNames(style.text)}>{item.text}</p>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default HomeSlider;
