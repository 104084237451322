import React, { useEffect } from "react";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";
import styles from "./termcondition.module.css";
import AdBar from "../Components/AdsBar";

export default function TermsCondition() {
  useEffect(() => {
    document.title = "Ms Tech | Terms & Conditions";
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <AdBar />
      <NavBar />

      <div className={styles.outer}>
        <div className={styles.containter}>
          <div className={styles.backImges}></div>
          <div>
            <h1 className={styles.heading}>Terms of Service</h1>
            <p className={styles.Text}>
              Welcome to Ms Tech. These Terms of Service (“Terms”) outline the
              conditions for using the services provided by Spectrum Trusted
              Reseller (“we,” “us,” or “our”). By accessing or utilizing our
              services, you agree to adhere to these Terms. If you do not accept
              any part of these Terms, you must refrain from using our services.
            </p>
          </div>
          <div>
            <h2 className={styles.heading}>Agreement to Terms</h2>
            <p className={styles.Text}>
              By accessing and utilizing the services provided by Spectrum
              Trusted Reseller, you acknowledge that you have read, understood,
              and agree to be bound by these Terms and our Privacy Policy.
            </p>
          </div>
          <div>
            <h2 className={styles.heading}>Service Overview</h2>
            <p className={styles.Text}>
              Ms Tech offers internet connectivity and additional related
              services to both individuals and businesses. The specifics of your
              plan, such as speed, data limits, and pricing, are outlined in
              your service agreement.
            </p>
          </div>
          <div>
            <h2 className={styles.heading}>Eligibility Criteria</h2>
            <p className={styles.Text}>
              To access our services, you may need to create an account. You
              agree to provide accurate, up-to-date, and complete information
              during the registration process, and to update such information to
              maintain its accuracy. You are responsible for securing your
              account password and for all activities conducted under your
              account.
            </p>
          </div>
          <div>
            <h2 className={styles.heading}>Account Setup</h2>
            <p className={styles.Text}>
              We implement stringent security measures to protect your data,
              although we acknowledge that perfect online security is not
              achievable. Your Options
            </p>
          </div>
          <div>
            <h2 className={styles.heading}>User Obligations</h2>
            <p className={styles.Text}>
              You agree to use our services solely for lawful purposes and in
              compliance with all applicable laws and regulations. You shall
              not:
              <br />
              Use the services in any way that could damage, disrupt, or
              overload our servers or networks
              <br />
              Attempt to gain unauthorized access to any part of our services or
              accounts.
              <br />
              Use the services in violation of others' rights, including
              intellectual property rights. Engage in any behavior that disrupts
              or interferes with the services.
            </p>
          </div>
          <div>
            <h2 className={styles.heading}>Billing and Payments</h2>
            <p style={{ marginBottom: "40px" }} className={styles.Text}>
              You agree to pay all fees for the services you subscribe to,
              including applicable taxes and charges. Payments are due on the
              dates outlined in your service agreement. Failure to pay on time
              may result in the suspension or cancellation of your services.
              Modifications to Services and Terms: We reserve the right to
              modify or discontinue, temporarily or permanently, any or all of
              our services with or without notice. We also reserve the right to
              update these Terms at any time. Any changes will take effect
              immediately upon posting on our website. Continued use of the
              services after any such modifications constitutes your acceptance
              of the revised Terms.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
