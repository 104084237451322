import React, { useEffect } from "react";
import NavBar from "../Components/NavBar";
import styles from "./aboutUs.module.css";
import classNames from "classnames";
import Footer from "../Components/Footer";
import AdBar from "../Components/AdsBar";
export default function AboutUs() {
  useEffect(() => {
    document.title = "Ms Tech | About Us";
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <AdBar />
      <NavBar />
      <div className={styles.outer}>
        <div className={styles.containter}>
          <div className={styles.backImges}></div>
          <div>
            <h1 className={styles.heading}>About Us </h1>
            <p className={styles.Text}>
              Welcome to Ms Tech! This platform is your go-to resource for
              detailed information about various internet plans available to
              consumers. Our goal is to help users make well-informed decisions
              by comparing internet service providers, their pricing, plans, and
              features. Through this website, we strive to simplify the process
              of selecting the ideal internet plan by providing
              easy-to-understand guides and in-depth comparisons tailored to
              your needs.
            </p>
          </div>
          <div>
            <h2 className={styles.heading}>
              Unlocking the Power of Digital Possibilities:
            </h2>
            <p className={styles.Text}>
              Our ethos at Ms Tech is deeply rooted in the unwavering commitment
              to bridging the divide between your current digital experience and
              the unexplored horizons of possibilities. We are not just offering
              services; we are crafting pathways where technology, innovation,
              and everyday living converge, ensuring every click, every call,
              and every stream is a journey into a world of infinite
              possibilities. Your Perfect Digital Companion
            </p>
          </div>
          <div>
            <h2 className={styles.heading}>High-Speed Internet:</h2>
            <p className={styles.Text}>
              At Ms Tech, our core belief lies in our steadfast dedication to
              narrowing the gap between your current digital experience and the
              boundless opportunities ahead. We go beyond providing services—we
              create avenues where technology, innovation, and daily life
              seamlessly intersect. With every click, call, and stream, we aim
              to guide you into a realm of limitless possibilities. Your
              Ultimate Digital Partner.
            </p>
          </div>
          <div>
            <h2 className={styles.heading}>Crystal-Clear Cable Services:</h2>
            <p className={styles.Text}>
              Experience the magic of cinema from the comfort of your living
              room with our cable services. Enjoy a wide selection of channels
              offering stunning, high-definition visuals, powered by
              cutting-edge technology and delivered with precision by our
              skilled technicians.
            </p>
          </div>
          <div>
            <h2 className={styles.heading}>Personalized Phone Plans:</h2>
            <p className={styles.Text}>
              At Ms Tech, we understand that communication preferences are as
              unique as individuals themselves. That’s why our phone plans are
              designed to cater to your specific needs, ensuring every call
              connects you to a world where clarity and convenience come
              together seamlessly.
            </p>
          </div>
          <div>
            <h2 className={styles.heading}>Proactive Support:</h2>
            <p className={styles.Text}>
              We are redefining customer service by providing support that goes
              beyond availability to anticipation. Our dedicated team stays one
              step ahead, delivering swift, efficient, and personalized
              solutions to meet your needs seamlessly.
            </p>
          </div>
          <div>
            <h2 className={styles.heading}>Nationwide Reach:</h2>
            <p className={styles.Text}>
              Our reach knows no limits. At Ms Tech, we are committed to being
              wherever you are, delivering exceptional internet, cable, and
              phone services that go beyond accessibility to become a staple of
              every American household.
            </p>
          </div>

          <div>
            <h2 className={styles.heading}>Committed to Innovation</h2>
            <p style={{ marginBottom: "40px" }} className={styles.Text}>
              At Ms Tech, innovation isn't just a concept—it's the driving force
              behind everything we do. We continually adapt, leveraging
              cutting-edge technologies and trends to ensure your digital
              journey is not only up-to-date but forward-thinking. Our team of
              experts thrives on pushing limits, transforming challenges into
              opportunities and every interaction into a story of endless
              possibilities.
              <br />
              <br />
              Your journey into a realm where every digital interaction unveils
              new possibilities begins with Ms Tech. More than just a service
              provider, we are your dedicated partner in a digital voyage,
              making every moment online one of unparalleled speed, clarity, and
              innovation. Welcome to Ms Tech—where every connection opens the
              door to digital marvels, and every subscriber becomes part of a
              community where the future of connectivity is not just imagined
              but lived. Your grand digital adventure starts here!
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
