import React, { useEffect } from "react";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";
import styles from "./discliamber.module.css";
import AdBar from "../Components/AdsBar";
export default function Disclaimer() {
  useEffect(() => {
    document.title = "Ms Tech | Disclaimer ";
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <AdBar />
      <NavBar />

      <div className={styles.outer}>
        <div className={styles.containter}>
          <div className={styles.backImges}></div>
          <div>
            <h1 className={styles.heading}>Disclaimer</h1>
            <p style={{ marginBottom: "40px" }} className={styles.Text}>
              At Ms Tech, we strive to provide accurate and reliable content;
              however, we do not guarantee the completeness, accuracy, or
              timeliness of the information on our site. Ms Tech is not liable
              for any errors, omissions, or inaccuracies that may occur. We are
              not responsible for any direct, indirect, incidental, or
              consequential damages arising from the use of the Service, or from
              any content associated with the Service.
            </p>
            <p className={styles.Text}>
              The content on Ms Tech is provided on an "as is" and "as
              available" basis, without any warranties or representations,
              either express or implied. Ms Tech does not guarantee that the
              Service will be uninterrupted, error-free, or free of viruses. We
              reserve the right to modify or remove any content without prior
              notice.
            </p>
            <p className={styles.Text}>
              As a distributor of third-party content, Ms Tech does not control
              or endorse the accuracy or reliability of such content. All
              warranties regarding the information or services provided through
              the Ms Tech Service, including merchantability, fitness for a
              particular purpose, and non-infringement, are expressly
              disclaimed.
            </p>
            <p className={styles.Text}>
              Pricing and availability of products and services are subject to
              change without notice. Ms Tech does not warrant the accuracy of
              any pricing or availability information provided on the site, nor
              does it guarantee uninterrupted access to the Service.
            </p>
            <p className={styles.Text}>
              Ms Tech is not responsible for the accuracy, legality, or decency
              of any content displayed through links to third-party websites or
              services that may appear on our site. We encourage you to read the
              privacy policies and terms of service of any external websites
              linked from our site.
            </p>
            <p className={styles.Text}>
              Any advice, opinion, or recommendation provided on this site does
              not constitute professional guidance. We suggest that users seek
              expert advice before making any decisions based on the information
              available through Ms Tech. The use of the Service or reliance on
              any content provided by the Service is at your own risk.
            </p>
            <p className={styles.Text}>
              In addition, Ms Tech makes no representations regarding the
              functionality of the Service or its compatibility with your device
              or network. We do not guarantee that the Service will meet your
              expectations or that any issues you encounter will be resolved to
              your satisfaction.
            </p>
            <p className={styles.Text}>
              We reserve the right to modify, suspend, or terminate access to
              all or part of the Service at any time, without notice, for any
              reason, including but not limited to maintenance, updates, or
              security concerns.
            </p>
            <p className={styles.Text}>
              Ms Tech assumes no responsibility for any loss or damage to data,
              software, or devices resulting from the use of our Service or the
              reliance on any information or services provided. We are not
              responsible for any interruptions to the Service or delays in the
              availability of content or services.
            </p>
            <p className={styles.Text} style={{ marginBottom: "40px" }}>
              By using the Ms Tech Service, you acknowledge that you have read,
              understood, and agreed to these terms. If you do not agree with
              any of the terms outlined in this disclaimer, you should
              immediately discontinue use of the Service.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
