import React, { useEffect } from "react";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";
import styles from ".//internet.module.css";
import AdBar from "../Components/AdsBar";

export default function Internet() {
  useEffect(() => {
    document.title = "Ms Tech | Internet";
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div>
        <AdBar />
        <NavBar />
        <div className={styles.outer}>
          <div className={styles.containter}>
            <div className={styles.backImges}></div>
            <div>
              <h1 className={styles.heading}>Next-Gen Fiber Optic Internet</h1>
              <p className={styles.Text}>
                Welcome to Ms Tech, where we redefine connectivity with
                cutting-edge fiber optic internet services. Our solutions
                deliver blazing fast speeds, perfect for both personal and
                business use, ensuring seamless experiences for all your online
                activities.
              </p>
            </div>
            <div>
              <h2 className={styles.heading}>Tailored Fiber Optic Plans</h2>
              <p className={styles.Text}>
                At Ms Tech, we understand that each customer has unique needs.
                Our fiber optic plans are fully customizable, with options that
                suit everything from high-definition streaming to heavy data
                usage, ensuring the best experience possible.
              </p>
            </div>
            <div>
              <h2 className={styles.heading}>Unrivaled Speed and Stability</h2>
              <p className={styles.Text}>
                With fiber optic technology, you can expect internet speeds that
                leave traditional connections behind. Experience fast downloads,
                low-latency gaming, and uninterrupted video calls with speeds
                that can reach up to 10Gbps.
              </p>
            </div>
            <div>
              <h2 className={styles.heading}>Reliable Nationwide Access</h2>
              <p className={styles.Text}>
                No matter where you are, Ms Tech ensures that fast and reliable
                internet is available. Our nationwide network provides coverage
                across cities, towns, and even remote locations, so you can stay
                connected no matter where life takes you.
              </p>
            </div>
            <div>
              <h2 className={styles.heading}>Flexible Phone Plans</h2>
              <p className={styles.Text}>
                Our flexible phone plans are designed to suit your communication
                preferences. From unlimited calls to international options, we
                provide affordable plans that prioritize both value and
                performance.
              </p>
            </div>
            <div>
              <h2 className={styles.heading}>Superior Security</h2>
              <p className={styles.Text}>
                Ms Tech ensures that your online activities remain safe and
                secure. Our fiber optic internet comes with enhanced encryption
                and state-of-the-art security protocols, keeping your personal
                and financial data protected.
              </p>
            </div>
            <div>
              <h2 className={styles.heading}>Round-the-Clock Support</h2>
              <p className={styles.Text}>
                We are here for you 24/7. Whether you need assistance with
                technical issues, billing, or upgrading your plan, Ms Tech’s
                customer service team is always available to ensure you have the
                support you need.
              </p>
            </div>

            <div>
              <h2 className={styles.heading}>Join Ms Tech Today</h2>
              <p style={{ marginBottom: "40px" }} className={styles.Text}>
                Join the Ms Tech community and enjoy fast, reliable, and secure
                fiber optic internet services. Whether you're working from home
                or streaming your favorite shows, we offer the speed and
                performance that you deserve. Explore your personalized internet
                plan today and take your online experience to the next level
                with Ms Tech.
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
