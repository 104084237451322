import React, { useEffect } from "react";
import NavBar from "../Components/NavBar";
import styles from "./contsctus.module.css";
import classNames from "classnames";
import Footer from "../Components/Footer";
import AdBar from "../Components/AdsBar";

export default function ContactUs() {
  useEffect(() => {
    document.title = "Ms Tech | Homephone";
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <AdBar />
      <NavBar />
      <div className={styles.outer}>
        <div className={styles.containter}>
          <div className={styles.backImges}></div>
          <div>
            <h1 className={styles.heading}>Reliable Homephone Services</h1>
            <p className={styles.Text}>
              At Ms Tech, we cherish the value of dependable, crystal-clear, and
              budget-friendly home phone services. In a world overwhelmed by
              digital noise, we focus on preserving the simplicity and
              reliability of traditional voice communication, skillfully merging
              the best aspects of both classic and modern technologies to
              provide personalized solutions.
            </p>
          </div>
          <div>
            <h2 className={styles.heading}>Tailored Communication Plans</h2>
            <p className={styles.Text}>
              Every person, family, and business has specific communication
              requirements. At Ms Tech, we craft our home phone services to
              address these unique needs precisely. Whether you need unlimited
              local calls, global calling options, or services with rich
              features, our plans are designed to perfectly align with your
              demands.
            </p>
          </div>
          <div>
            <h2 className={styles.heading}>Exceptional Call Quality</h2>
            <p className={styles.Text}>
              Step into a world where every conversation is defined by superb
              sound clarity and flawless connectivity. Ms Tech stands for
              dependability, ensuring that every word, pause, and sentiment is
              transmitted with complete precision.
            </p>
          </div>
          <div>
            <h2 className={styles.heading}>Advanced Communication Features</h2>
            <p className={styles.Text}>
              Our home phone packages come with advanced features that elevate
              your calling experience. Voicemails are transformed from ordinary
              messages into meaningful, accessible communications. Caller ID
              provides insightful details, effortlessly integrating technology
              into every conversation.
            </p>
          </div>
          <div>
            <h2 className={styles.heading}>
              Seamless International Connectivity
            </h2>
            <p className={styles.Text}>
              Ms Tech eliminates the barriers of international calling, making
              it as simple and cost-effective as local calls. No matter the
              distance, you can stay connected to the world with ease.
            </p>
          </div>
          <div>
            <h2 className={styles.heading}>Comprehensive Customer Support</h2>
            <p className={styles.Text}>
              Our customer service goes beyond basic assistance—it’s a
              commitment to excellence. We address every query and concern with
              expertise and care, consistently exceeding your expectations.
            </p>
          </div>

          <div>
            <h2 className={styles.heading}>Find Your Ideal Plan</h2>
            <p style={{ marginBottom: "40px" }} className={styles.Text}>
              Explore a world where tradition meets innovation—where every call
              reflects clarity, reliability, and cutting-edge functionality. Ms
              Tech offers more than just a service; we provide an experience
              that not only meets but celebrates your communication needs with
              unmatched sophistication. Discover our customized plans and step
              into a world where every call is a symbol of excellence and
              quality.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
