import React, { useState } from "react";
import styles from "./faq.module.css";
import classNames from "classnames";
import commonstyle from "./faq.module.css";
import { Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { FaPlus } from "react-icons/fa";

export default function FAQS() {
  const accordionData = [
    {
      header:
        "Where can I access Ms Tech Home Internet? And what home internet services are available?",
      Content:
        "The availability of Ms Tech Home Internet depends on the technology used to deliver it and your location. Fios Home Internet is available in many metro and suburban areas of New England and the Mid-Atlantic. 5G Home Internet is expanding to more cities nationwide. LTE Home Internet, powered by Ms Tech's 4G LTE network, is available in areas where Fios and 5G Home Internet aren't. To find out which Ms Tech Home Internet service is available in your area, simply enter your address and check availability.",
    },
    {
      header: "What is 5G Home Internet?",
      Content:
        "5G Home Internet is a high-speed wireless home internet service powered by Ms Tech's 5G Ultra Wideband. It offers fast, reliable service that can support multiple connected devices. No annual contracts, hidden fees, data overages, or extra equipment charges. Get great pricing with Auto Pay and a service price guaranteed for up to 3 years. If you're switching from another provider, Ms Tech offers up to a $500 credit to cover early termination fees. Simple, plug-and-play setup with 30 days of support included. Check availability by entering your address above.",
    },
    {
      header: "What is Fios Internet?",
      Content:
        "Fios Internet is a 100% fiber-optic network that provides some of the fastest internet speeds in the country. It uses fiber optics to deliver ultra-fast speeds with powerful bandwidth, perfect for homes with multiple devices. Ms Tech is one of the few providers offering symmetrical upload and download speeds on most of our plans, which is especially useful for video calls, gaming, and sharing large files.",
    },
    {
      header: "What is LTE Home Internet?",
      Content:
        "LTE Home Internet uses Ms Tech's 4G LTE network to provide broadband service to homes where Fios and 5G Home Internet aren't available. With LTE Home Internet, you can stream videos in 1080p and enjoy unlimited data without worrying about data caps.",
    },
    {
      header:
        "Can Ms Tech cover my early termination fee when I switch to Ms Tech Home Internet?",
      Content:
        "Yes! If you're currently under contract with another internet provider, Ms Tech can help cover your early termination fee with up to a $500 credit. To qualify, submit your request within 90 days of setting up Ms Tech Home Internet. For Fios customers, follow the Fios Early Termination Fee instructions, and for 5G Home customers, visit the 5G Home Internet bill credit page.",
    },
    {
      header:
        "Can I transfer my Ms Tech Home Internet service to a new address?",
      Content:
        "We make it simple to stay connected when you move. Visit our page on transferring Ms Tech Home Internet to a new address, or log in to your account to get started.",
    },
    {
      header: "What is Ms Tech Whole-Home Wi-Fi?",
      Content:
        "Ms Tech Whole-Home Wi-Fi includes features like Wi-Fi Health Check in the My Ms Tech app to optimize your home network, and an optional Wi-Fi Extender to boost signal strength in hard-to-reach areas. If you cancel your service or change to a plan without Whole-Home Wi-Fi, you will need to return the Wi-Fi Extender to avoid a $175 unreturned equipment fee.",
    },
    {
      header: "What affordable Ms Tech Home Internet options are available?",
      Content:
        "Ms Tech participates in the Affordable Connectivity Program (ACP), offering reduced-cost internet services to eligible customers. If you qualify for Lifeline, SNAP, WIC, or other assistance programs, or received a Federal Pell Grant in the past year, you may be eligible for discounts on Fios, 5G Home, or LTE Home Internet plans. Learn more about the Ms Tech Forward program here.",
    },
  ];

  const [expanded, setExpanded] = useState(null);

  const handleAccordionChange = (panelIndex) => (event, isExpanded) => {
    setExpanded(isExpanded ? panelIndex : null);
  };

  return (
    <div className={styles.container}>
      {accordionData.map((data, index) => (
        <div key={index} className={styles.mt8}>
          <Accordion
            className={styles.dropdown}
            expanded={expanded === index}
            onChange={handleAccordionChange(index)}
          >
            <AccordionSummary
              sx={{
                ".MuiAccordionSummary-expandIconWrapper": {
                  transform: "none",
                  transition: "none",
                },
              }}
              style={{ padding: "10px 0px" }}
              expandIcon={
                <FaPlus
                  className={classNames(styles.icon, {
                    [styles.rotate]: expanded === index,
                    [styles.colorChanged]: expanded === index,
                  })}
                />
              }
              aria-controls={`panel${index + 1}-content`}
              id={`panel${index + 1}-header`}
            >
              <Typography style={{ fontWeight: "700" }}>
                <div>
                  <p
                    className={classNames(commonstyle.fs16)}
                    style={{
                      color: expanded === index ? "blue" : "blue",
                      textAlign: "start",
                    }}
                  >
                    {data.header}
                  </p>
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{
                padding: "8px 0px 16px",
                textAlign: "start",
                textJustify: "none",
              }}
            >
              <Typography>
                <div>
                  <p style={{ fontSize: "14px", color: "#666" }}>
                    {data.Content}
                  </p>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      ))}
    </div>
  );
}
