import React from "react";
import styles from "./footer.module.css";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export default function Footer() {
  const navigate = useNavigate();
  const handleGotermsCondition = () => {
    navigate("/termsCondition");
  };
  const handleGoPrivacy = () => {
    navigate("/privacyPolicy");
  };
  const handleGoDisclaimer = () => {
    navigate("/disclaimer");
  };
  const handleGoAbout = () => {
    navigate("/aboutUs");
  };
  return (
    <footer className={styles.footer}>
      <div className={styles.footerouter}>
        <h1 style={{ textAlign: "center" }}>Disclaimer</h1>
        <p
          style={{
            textAlign: "center",
            marginTop: "16px",
            fontSize: "20px",
            padding: "20px",
          }}
        >
          This website is not the official site of Version, Ms Tech,
          CenturyLink, AT&T, Frontier, Xfinity, or any other Telecommunications
          Service Provider. It is the official site of Version Trusted Reseller
          (Marketing Partner). We may send updates to the mobile number or email
          address registered with us. The information provided on this website
          is for informational purposes only and does not constitute an offer to
          purchase or subscribe to any services. Prices are subject to change
          without prior notice, and availability of properties is not
          guaranteed. Images shown are for representation only. The logos and
          images displayed on this website are the exclusive property of their
          respective owners and are protected by applicable copyright laws.
          Version Trusted Reseller does not claim ownership of these materials;
          they are used here solely for informational purposes.
        </p>

        <div className={styles.footerContainer}>
          <div className={styles.footerSection}>
            <h1 style={{ fontSize: "35px" }}>Ms Tech Trusted Reseller</h1>
            <ul style={{ listStyle: "none", fontSize: "18px" }}>
              <li style={{ marginTop: "24px" }}>
                17 Hobson PL suit B Huntington, NY 11743
              </li>
              <a style={{ textDecoration: "none" }} href="tel:+1(888) 412-0673">
                <li style={{ marginTop: "10px", color: "white" }}>
                  +1(888) 412-0673
                </li>
              </a>
            </ul>
          </div>
          <div className={styles.footerSection}>
            <h2>Quick Links</h2>
            <ul className={styles.footerLinks}>
              <li>
                <FaArrowRight />
                <p onClick={handleGoAbout}>About Us</p>
              </li>
              <li>
                <FaArrowRight />

                <p onClick={handleGoDisclaimer}>Disclaimer</p>
              </li>
              <li>
                <FaArrowRight />

                <p onClick={handleGoPrivacy}>Privacy policy</p>
              </li>
              <li>
                <FaArrowRight />

                <p onClick={handleGotermsCondition}>Terms And Condition</p>
              </li>
            </ul>
          </div>

          <div className={styles.footerSection}>
            <h2 style={{ marginBottom: "24px" }}>Our Newslette</h2>
            <p style={{ fontSize: "20px" }}>
              Sign up to Private's weekly newsletter to get the latest updates.
            </p>
            <div className={styles.inputFlx}>
              <input placeholder="Email Address"></input>
              <button className={styles.SubscribeBTN}>Subscribe</button>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footerBottom}>
        <div className={styles.bottomOuter}>
          <p>&copy; 2024 Version Trusted Reseller. All rights reserved.</p>
          <div className={styles.flxx}>
            <div
              onClick={handleGotermsCondition}
              style={{
                textDecoration: "none",
                color: "white",
                cursor: "pointer",
              }}
            >
              <p>Terms & Conditions</p>
            </div>{" "}
            <div
              onClick={handleGoPrivacy}
              style={{
                textDecoration: "none",
                color: "white",
                cursor: "pointer",
              }}
            >
              <p>Privacy Policy</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
