import React, { useEffect, useState } from "react";
import NavBar from "../Components/NavBar";
import styles from "./specturm.module.css";
import classNames from "classnames";
import Footer from "../Components/Footer";
import AdBar from "../Components/AdsBar";
import FAQS22 from "../Components/FAQs/fass";
import logo from "../assests/WhatsApp Image 2024-11-20 at 23.00.48_b7a7ce1a.jpg";
import { style } from "@mui/system";
import { IoClose } from "react-icons/io5";

export default function Spectrum() {
  useEffect(() => {
    document.title = "Ms Tech | Spectrum";
    window.scrollTo(0, 0);
  });

  const [isPopoutVisible, setIsPopoutVisible] = useState(true);

  const handleClosePopout = () => {
    setIsPopoutVisible(false); // Hide only the popouter div
  };
  return (
    <div>
      <AdBar />
      <NavBar />
      <div>
        {isPopoutVisible && ( // Conditionally render the popouter div
          <div className={styles.popouter}>
            <div
              style={{
                background: "#003057",
                color: "white",
                textAlign: "center",
                padding: "15px",
                boxSizing: "border-box",
                width: "100%",
                borderRadius: "8px 8px 0 0",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <a style={{ textDecoration: "none" }} href="tel:+1(888) 412-0673">
                <h2 style={{ color: "white", textAlign: "center" }}>
                  Call Now +1(888) 412-0673
                </h2>
              </a>
              {/* <IoClose className={styles.close} onClick={handleClosePopout} /> */}
            </div>
            <img src={logo} className={styles.popImg} alt="Logo" />
            <div>
              <a style={{ textDecoration: "none" }} href="tel:+1(888) 412-0673">
                <h1 style={{ color: "black" }}> Call Now </h1>
              </a>
            </div>
            <a style={{ textDecoration: "none" }} href="tel:+1(888) 412-0673">
              <button
                style={{
                  marginTop: "16px",
                  background: "#003057",
                  border: "#003057",
                  width: "290px",
                }}
                className={styles.btnCall}
              >
                +1(888) 412-0673
              </button>
            </a>
            <div
              style={{
                background: "#003057",
                color: "white",
                textAlign: "center",
                boxSizing: "border-box",
                padding: "15px",
                width: "100%",
                borderRadius: "0 0 8px 8px",
              }}
            >
              <a style={{ textDecoration: "none" }} href="tel:+1(888) 412-0673">
                <h3 style={{ color: "white", textAlign: "center" }}>
                  Call Now +1(888) 412-0673
                </h3>
              </a>
            </div>
          </div>
        )}
      </div>
      <div className={styles.mainBanner}>
        <div className={styles.bannerouter}>
          <img src={logo} className={styles.logo} />
          <div className={styles.w60}>
            <h1>
              SPECTRUM ONE <br /> Ultra-Fast Internet with Complimentary WiFi +
              Mobile
            </h1>
            <p style={{ marginTop: "16px" }}>
              Join Spectrum Internet® today and enjoy High-Speed WiFi and a FREE
              Unlimited Mobile line for 12 months – no contracts, data limits,
              or hidden charges.
            </p>

            <h1 style={{ marginTop: "24px" }}>300 Mbps Internet</h1>
            <div
              style={{
                display: "flex",
                gap: "10px",
              }}
            >
              <p
                style={{
                  fontSize: "32px",
                  fontWeight: "800",
                  marginTop: "24px",
                }}
              >
                ${" "}
              </p>
              <p style={{ fontSize: "152px", fontWeight: "800" }}>49</p>
              <p
                style={{
                  fontSize: "20px",
                  fontWeight: "800",
                  marginTop: "24px",
                }}
              >
                {" "}
                99 /mo <br /> for 12 months
              </p>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "30px" }}>
              <a style={{ textDecoration: "none" }} href="tel:+1(888) 412-0673">
                <button style={{ width: "240px" }} className={styles.btnCall}>
                  SHOP INTERNET
                </button>
              </a>
              <a style={{ textDecoration: "none" }} href="tel:+1(888) 412-0673">
                <p style={{ fontWeight: "600", fontSize: "18px" }}>
                  +1(888) 412-0673
                </p>
              </a>
            </div>
            <img
              src="https://internetreseller.us/assets/img/spectrum/fg-300freefreefree-d.avif"
              className={styles.banerImg}
            />
          </div>
        </div>
      </div>
      <div className={styles.outer}>
        <div className={styles.containter}>
          <div
            style={{ background: "#F8FDFF", padding: "50px" }}
            className={styles.p200}
          >
            <h1 style={{ textAlign: "center" }}>
              Make the Switch to Spectrum Mobile® and Save Big
            </h1>
            <h1
              style={{
                marginTop: "24px",
                fontWeight: "400",
                textAlign: "center",
              }}
            >
              Exclusive offer for Spectrum Internet customers: Get Unlimited
              Mobile for just $15/month per line for 12 months when you sign up
              for two lines. Discover More
            </h1>
          </div>
        </div>
      </div>
      <div className={styles.outer}>
        <div className={styles.containter}>
          <div style={{ marginTop: "40px" }}>
            <h1 style={{ textAlign: "center" }}>
              Experience Unrivaled Speed and Security
            </h1>
            <h1
              style={{
                marginTop: "24px",
                fontWeight: "400",
                textAlign: "center",
              }}
            >
              Elevate your home Internet with top-tier speed and security. Enjoy
              seamless connectivity with plans offering speeds up to 1 Gig.
            </h1>
          </div>
          <div
            style={{
              display: "flex",
              marginTop: "40px",
              alignItems: "center",
              justifyContent: "center",
              flexWrap: "wrap",
              gap: "24px",
              marginTop: "60px",
              marginBottom: "60px",
            }}
          >
            <div className={styles.carrdd}>
              <div
                style={{
                  background: "#00276d",
                  padding: "24px",
                  textAlign: "center",
                }}
              >
                <h1>BASIC INTERNET</h1>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  <p
                    style={{
                      fontSize: "32px",
                      fontWeight: "800",
                      marginTop: "24px",
                    }}
                  >
                    ${" "}
                  </p>
                  <p style={{ fontSize: "112px", fontWeight: "800" }}>49</p>
                  <p
                    style={{
                      fontSize: "20px",
                      fontWeight: "800",
                      marginTop: "24px",
                    }}
                  >
                    {" "}
                    99 /mo <br /> for 12 months
                  </p>
                </div>
              </div>
              <div style={{ padding: "30px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  {" "}
                  <button style={{ width: "190px" }} className={styles.btnCall}>
                    GET 300 MBPs
                  </button>
                  <img src="https://internetreseller.us/assets/img/spectrum/Fast_Icon.svg" />
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "#666",
                    }}
                  >
                    Fast
                  </p>
                </div>

                <p
                  style={{
                    textAlign: "center",
                    color: "black",
                    marginTop: "10px",
                  }}
                >
                  Reliable Internet speeds up to 300 Mbps
                </p>
              </div>
            </div>
            <div className={styles.carrdd}>
              <div
                style={{
                  background: "#00276d",
                  padding: "24px",
                  textAlign: "center",
                }}
              >
                <h1>ULTRA INTERNET</h1>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  <p
                    style={{
                      fontSize: "32px",
                      fontWeight: "800",
                      marginTop: "24px",
                    }}
                  >
                    ${" "}
                  </p>
                  <p style={{ fontSize: "112px", fontWeight: "800" }}>69</p>
                  <p
                    style={{
                      fontSize: "20px",
                      fontWeight: "800",
                      marginTop: "24px",
                    }}
                  >
                    {" "}
                    99 /mo <br /> for 24 months
                  </p>
                </div>
              </div>
              <div style={{ padding: "30px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  {" "}
                  <button style={{ width: "190px" }} className={styles.btnCall}>
                    GET 500 MBPs
                  </button>
                  <img src="https://internetreseller.us/assets/img/spectrum/Fast_Icon.svg" />
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "#666",
                    }}
                  >
                    Faster
                  </p>
                </div>

                <p
                  style={{
                    textAlign: "center",
                    color: "black",
                    marginTop: "10px",
                  }}
                >
                  Speeds up to 500 Mbps
                </p>
                <p
                  style={{
                    textAlign: "center",
                    color: "black",
                    marginTop: "10px",
                  }}
                >
                  Two-Year Price Guarantee
                </p>
              </div>
            </div>
            <div className={styles.carrdd}>
              <div
                style={{
                  background: "#00276d",
                  padding: "24px",
                  textAlign: "center",
                }}
              >
                <h1>BASIC INTERNET</h1>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  <p
                    style={{
                      fontSize: "32px",
                      fontWeight: "800",
                      marginTop: "24px",
                    }}
                  >
                    ${" "}
                  </p>
                  <p style={{ fontSize: "112px", fontWeight: "800" }}>79</p>
                  <p
                    style={{
                      fontSize: "20px",
                      fontWeight: "800",
                      marginTop: "24px",
                    }}
                  >
                    {" "}
                    99 /mo <br /> for 24 months
                  </p>
                </div>
              </div>
              <div style={{ padding: "30px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  {" "}
                  <button style={{ width: "190px" }} className={styles.btnCall}>
                    GET 300 MBPs
                  </button>
                  <img src="https://internetreseller.us/assets/img/spectrum/Fast_Icon.svg" />
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "#666",
                    }}
                  >
                    Fastest
                  </p>
                </div>

                <p
                  style={{
                    textAlign: "center",
                    color: "black",
                    marginTop: "10px",
                  }}
                >
                  Twice the speed for only $10/mo more
                </p>
                <p
                  style={{
                    textAlign: "center",
                    color: "black",
                    marginTop: "10px",
                  }}
                >
                  Two-Year Price Guarantee
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.Bnnerouter}>
        <h1>Enjoy Disney+ On Us</h1>
        <p
          style={{
            margin: "24px 0px",
            fontSize: "30px",
            fontWeight: "600",
            textAlign: "center",
          }}
        >
          Enjoy Disney+ Basic with Spectrum TV® Select. Immerse yourself in
          unlimited entertainment. Terms and conditions apply.{" "}
          <strong>Call Now</strong>
        </p>
      </div>
      <div className={styles.outer}>
        <div className={styles.containter}>
          <div
            style={{
              display: "flex",
              marginTop: "40px",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "24px",
              marginTop: "60px",
              marginBottom: "60px",
            }}
          >
            <div className={styles.w50}>
              <img
                src="https://internetreseller.us/assets/img/spectrum/HomePage_SelfInstall_Desktop.avif"
                className={styles.w50Img}
              />
            </div>
            <div className={styles.w50}>
              <div>
                <h1 style={{ color: "#1E6EE7", marginBottom: "16px" }}>
                  GET YOUR SPECTRUM INTERNET UP AND RUNNING IN MINUTES
                </h1>
                <h1 style={{ marginBottom: "16px" }}>
                  Simple and Fast Internet Setup
                </h1>
                <ul style={{ marginLeft: "20px" }}>
                  <li style={{ marginTop: "10px" }}>
                    Get online quickly with our easy self-installation kit
                  </li>
                  <li style={{ marginTop: "10px" }}>
                    No need for technician visits
                  </li>
                  <li style={{ marginTop: "10px" }}>
                    Pick up your kit at the store or have it delivered to your
                    home
                  </li>
                  <button className={styles.btnCall}>Shop Now</button>
                </ul>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "40px",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "24px",
              marginTop: "60px",
              marginBottom: "60px",
            }}
          >
            <div className={styles.carrd}>
              <img
                src="https://internetreseller.us/assets/img/spectrum/pc-xumo-lockup.avif"
                className={styles.carrdImg}
              />
              <div style={{ padding: "20px 40px 40px 40px " }}>
                <h2 style={{ color: "#1E6EE7" }}>XUMO STREAM BOX</h2>
                <h1 style={{ marginTop: "20px" }}>
                  $0/month for the first 6 months
                </h1>
                <h2 style={{ marginTop: "20px" }}>
                  The Xumo Stream Box combines live TV, On Demand content, and
                  top streaming services into one device!
                </h2>
                <p style={{ fontWeight: "600", marginTop: "40px" }}>
                  Get the Xumo Stream Box Now
                </p>
              </div>
            </div>
            <div className={styles.carrd}>
              <img
                src="https://internetreseller.us/assets/img/spectrum/pc-couple-laptop.avif"
                className={styles.carrdImg}
              />
              <div style={{ padding: "20px 40px 40px 40px " }}>
                <h2 style={{ color: "#1E6EE7" }}>SPECTRUM INTERNET REVIEWS</h2>
                <h1 style={{ marginTop: "20px" }}>
                  See What Our Customers Say
                </h1>
                <h2 style={{ marginTop: "20px" }}>
                  Explore honest feedback and reviews about Spectrum Internet
                </h2>
                <p style={{ fontWeight: "600", marginTop: "40px" }}>
                  Read Customer Reviews
                </p>
              </div>
            </div>
            <div className={styles.carrd}>
              <img
                src="https://internetreseller.us/assets/img/spectrum/image-working.avif"
                className={styles.carrdImg}
              />
              <div style={{ padding: "20px 40px 40px 40px " }}>
                <h2 style={{ color: "#1E6EE7" }}>WI-FI</h2>
                <h1 style={{ marginTop: "20px" }}>Stay Connected, Always</h1>
                <h2 style={{ marginTop: "20px" }}>
                  Learn how Spectrum ensures reliable and secure WiFi for
                  seamless connectivity.
                </h2>
                <p style={{ fontWeight: "600", marginTop: "40px" }}>
                  Check Spectrum WiFi Coverage
                </p>
              </div>
            </div>
          </div>

          <div style={{ marginTop: "24px" }}>
            <h1 style={{ textAlign: "center" }}>
              Enjoy a Fast and Secure Internet Experience
            </h1>
            <div
              className={styles.flxx}
              style={{ justifyContent: "center", marginTop: "40px" }}
            >
              <div className={styles.w100}>
                <img
                  src="https://internetreseller.us/assets/img/spectrum/icon-internet-mediumblue.svg"
                  className={styles.MMImage}
                />
                <h1 style={{ color: "#666", marginTop: "10px" }}>Internet</h1>
              </div>
              <div className={styles.w100}>
                <img
                  src="https://internetreseller.us/assets/img/spectrum/icon-tv-mediumblue.svg"
                  className={styles.MMImage}
                />
                <h1 style={{ color: "#666", marginTop: "10px" }}>TV</h1>
              </div>
              <div className={styles.w100}>
                <img
                  src="https://internetreseller.us/assets/img/spectrum/icon-voice-mediumblue.svg"
                  className={styles.MMImage}
                />
                <h1 style={{ color: "#666", marginTop: "10px" }}>Voice</h1>
              </div>
              <div className={styles.w100}>
                <img
                  src="https://internetreseller.us/assets/img/spectrum/icon-mobile-mediumblue.svg"
                  className={styles.MMImage}
                />
                <h1 style={{ color: "#666", marginTop: "10px" }}>Mobile</h1>
              </div>
            </div>
          </div>
          <p style={{ color: "#666", marginTop: "50px" }}>
            Internet Offer Details
            <br />
            <br />
            Limited-time promotion; new residential customers only (no previous
            Spectrum service within the last 30 days) and must be in good
            standing with Spectrum. Additional taxes and fees may apply
            depending on location. Standard pricing applies after the promo
            period. Installation may incur extra fees. Speeds may vary based on
            location and network conditions.
            <br />
            Bundled Offer Details
            <br />
            <br />
            Limited-time offer for new residential customers only. Excludes
            mobile devices. Taxes and fees apply in some areas. All standard
            rates apply after the promotional period. Free Unlimited line offer
            valid for 12 months with a statement credit.
            <br />
            Mobile Offer Details
            <br />
            <br />
            Limited-time offer for new residential customers only, without prior
            obligations to Spectrum. Mobile devices not included. Standard rates
            apply after the promo period. Reduced speeds after certain usage
            thresholds.
            <br />
            Cable TV Offer Details
            <br />
            <br />
            Offer applies to Disney+ Basic (with ads). You must be at least 18
            years old to redeem. If you're an existing Disney+ subscriber,
            you’ll receive additional subscriptions. You must stay on an
            eligible plan to maintain access.
            <br />
            <br />
            FREE XUMO STREAM BOX: One box per account, redeemable when
            purchasing qualifying Spectrum TV service. Spectrum Internet
            required. Other terms apply.
          </p>

          <div>
            <h1
              style={{
                color: "#1E6EE7",
                margin: "50px 0px 30px 0 ",
                textAlign: "center",
              }}
            >
              Have questions? We're ready to assist you.
            </h1>
            <FAQS22 />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
