import React, { useEffect } from "react";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";
import styles from "./privacyPolicy.module.css";
import AdBar from "../Components/AdsBar";
import classNames from "classnames";

export default function PrivacyPolicy() {
  useEffect(() => {
    document.title = "Ms Tech | Privacy & Policy";
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <AdBar />
      <NavBar />

      <div className={classNames(styles.outer, styles.mmmm)}>
        <div className={styles.containter}>
          <div className={styles.backImges}></div>
          <div>
            <h1 className={styles.heading}>Privacy Policy</h1>
            <p className={styles.Text}>
              At Ms Tech, we take your privacy seriously. This Privacy Policy
              outlines how we collect, use, and protect your personal
              information when you visit our website or utilize our services. By
              engaging with our services, you consent to the practices described
              in this policy.
            </p>
          </div>
          <div>
            <h2 className={styles.heading}>Information Collection</h2>
            <p className={styles.Text}>
              We collect various types of information to provide you with
              seamless services:
              <ul>
                <li>
                  <strong>Personal Information:</strong> Includes your name,
                  contact details, and billing information, essential for
                  managing your account and providing services.
                </li>
                <li>
                  <strong>Usage Details:</strong> Information about how you
                  interact with our website and services, including device
                  information, IP addresses, and browsing activity.
                </li>
                <li>
                  <strong>Cookies & Tracking Technologies:</strong> We use these
                  tools to optimize your experience and gather insights on how
                  you engage with our website and services.
                </li>
              </ul>
            </p>
          </div>
          <div>
            <h2 className={styles.heading}>Description of Services</h2>
            <p className={styles.Text}>
              Ms Tech provides internet connectivity services, including
              high-speed internet plans and additional services such as customer
              support. The specifics of your chosen plan, including speeds, data
              limits, and pricing, are detailed in your service agreement.
            </p>
          </div>
          <div>
            <h2 className={styles.heading}>Information Utilization</h2>
            <p className={styles.Text}>
              We use the collected information to:
              <ul>
                <li>
                  <strong>Provide Services:</strong> Ensure smooth delivery of
                  our services, including billing, support, and troubleshooting.
                </li>
                <li>
                  <strong>Enhance User Experience:</strong> Customize and
                  improve the services you use and innovate for better user
                  engagement.
                </li>
                <li>
                  <strong>Marketing Communications:</strong> Provide updates,
                  promotions, and offers, with an option for you to unsubscribe
                  at any time.
                </li>
                <li>
                  <strong>Legal Compliance:</strong> Fulfill our legal
                  obligations and resolve any disputes that may arise.
                </li>
              </ul>
            </p>
          </div>
          <div>
            <h2 className={styles.heading}>Information Disclosure</h2>
            <p className={styles.Text}>
              We do not sell your personal information. However, we may share
              your information with the following parties:
              <ul>
                <li>
                  <strong>Affiliates:</strong> Trusted third-party entities that
                  assist in providing our services.
                </li>
                <li>
                  <strong>Legal Entities:</strong> We may disclose your
                  information when required by law, such as in response to court
                  orders or government regulations.
                </li>
              </ul>
            </p>
          </div>
          <div>
            <h2 className={styles.heading}>Data Security</h2>
            <p className={styles.Text}>
              We implement robust security measures to protect your personal
              information from unauthorized access or disclosure. While no
              online platform can guarantee 100% security, we strive to ensure
              the protection of your information to the best of our ability.
            </p>
          </div>
          <div>
            <h2 className={styles.heading}>Your Rights and Options</h2>
            <p className={styles.Text}>
              You have the following rights concerning your personal
              information:
              <ul>
                <li>
                  <strong>Access and Edit:</strong> You can review, amend, or
                  delete your personal information at any time.
                </li>
                <li>
                  <strong>Opt-out of Marketing:</strong> Unsubscribe from
                  promotional emails or communications.
                </li>
                <li>
                  <strong>Cookie Management:</strong> You can manage cookie
                  settings through your browser.
                </li>
                <li>
                  <strong>Minors' Privacy:</strong> We do not knowingly collect
                  personal information from individuals under 13 years of age.
                </li>
              </ul>
            </p>
          </div>
          <div>
            <h2 className={styles.heading}>Policy Changes</h2>
            <p className={styles.Text}>
              We may update this Privacy Policy from time to time. Please check
              the "Effective Date" at the top of this page to stay informed
              about any modifications.
            </p>
          </div>
          <div>
            <h2 className={styles.heading}>Contact Us</h2>
            <p style={{ marginBottom: "40px" }} className={styles.Text}>
              If you have any questions or concerns about this Privacy Policy or
              the way your information is handled, please contact us at{" "}
              <a href="mailto:mstechssolution.Contact@mssolution.org">
                mstechssolution.Contact@mssolution.org
              </a>
              . We value your privacy and appreciate your trust in Ms Tech to
              handle your information responsibly.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
